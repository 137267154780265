var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menuTreeTableComponent"},[_c('div',{staticClass:"header-content"},[_c('div',{staticClass:"g-left-operation"},[_c('i',{staticClass:"el-icon-s-unfold"}),_c('span',[_vm._v(_vm._s(_vm.operationTitle))])]),_c('div',{staticClass:"g-right-operation"},[_vm._t("rightOperationBtn")],2)]),_c('CommonTreeTable',{attrs:{"tableLoading":_vm.tableLoading,"data":_vm.data,"expand-all":true,"columns":_vm.cmmonMenuCols,"height":"auto","border":"","size":"small"},scopedSlots:_vm._u([{key:"iconSlot",fn:function(ref){
var row = ref.scoped;
return [_c('svg-icon',{attrs:{"icon-class":row[row.prop]}})]}},{key:"sortSlot",fn:function(ref){
var row = ref.scoped;
return [_c('el-tag',[_vm._v(_vm._s(row[row.prop]))])]}},{key:"is_frameSlot",fn:function(ref){
var row = ref.scoped;
return [(!row.is_frame)?_c('span',[_vm._v("是")]):_c('span',[_vm._v("否")])]}},{key:"is_showSlot",fn:function(ref){
var row = ref.scoped;
return [(row.is_show)?_c('span',[_vm._v("是")]):_c('span',[_vm._v("否")])]}}])},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }