<template>
  <div>
    <el-button :disabled="data.id === 1" type="warning" size="mini"  @click="to">新增子节点</el-button>
    <eForm ref="form" :menus="menus" :sup_this="sup_this" :is-add="false" createChild/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
    menus: {
      type: Array,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        component: this.data.component,
        name: this.data.name,
        sort: this.data.sort,
        pid: this.data.pid,
        path: this.data.path,
        is_show: this.data.is_show.toString(),
        is_frame: this.data.is_frame.toString(),
        icon: this.data.icon }
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
